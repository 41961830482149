import React, { useState } from 'react';
import axios from 'axios';

export default function App() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [formData, setFormData] = useState({
    date: '',
    latitude: '',
    longitude: '',
  });

  const fetchPanchangData = async () => {
    setLoading(true);
    setError(null);

    const { date, latitude, longitude } = formData;
    const apiUrl = 'https://api.prokerala.com/v2/astrology/panchang/advanced';

    try {
      const response = await axios.get(apiUrl, {
        params: { date, latitude, longitude },
        headers: {
          Authorization: 'zBgXx5x99bbGoUmozJZCHhN3NqeQLWYxDRXvPUz2'
        },
      });
      setData(response.data);
    } catch (err) {
      setError(err.message || 'Something went wrong.');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div style={{ padding: '20px' }}>
      <h1>Panchang Advanced</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          fetchPanchangData();
        }}
      >
        <div>
          <label>
            Date:
            <input
              type="date"
              name="date"
              value={formData.date}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Latitude:
            <input
              type="number"
              name="latitude"
              value={formData.latitude}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <div>
          <label>
            Longitude:
            <input
              type="number"
              name="longitude"
              value={formData.longitude}
              onChange={handleInputChange}
              required
            />
          </label>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Fetching...' : 'Get Panchang'}
        </button>
      </form>

      {error && <p style={{ color: 'red' }}>Error: {error}</p>}

      {data && (
        <div style={{ marginTop: '20px' }}>
          <h2>Panchang Data</h2>
          <pre style={{ background: '#f4f4f4', padding: '10px' }}>
            {JSON.stringify(data, null, 2)}
          </pre>
        </div>
      )}
    </div>
  );
}